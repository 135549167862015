import React from "react";

export default ({ color }) => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22 40.3333C24.0167 40.3333 25.6667 38.6833 25.6667 36.6667H18.3333C18.3333 38.6833 19.9833 40.3333 22 40.3333Z" fill="#333333" />
    <path d="M16.5 34.8333H27.5C28.5083 34.8333 29.3333 34.0083 29.3333 33C29.3333 31.9917 28.5083 31.1667 27.5 31.1667H16.5C15.4917 31.1667 14.6667 31.9917 14.6667 33C14.6667 34.0083 15.4917 34.8333 16.5 34.8333Z" fill="#333333" />
    <path d="M22 3.66667C14.41 3.66667 8.25 9.82667 8.25 17.4167C8.25 24.42 13.1267 28.16 15.1617 29.3333H28.8383C30.8733 28.16 35.75 24.42 35.75 17.4167C35.75 9.82667 29.59 3.66667 22 3.66667Z" fill="#333333" />
    <path d="M16.9509 13.9807C17.7726 12.6511 19.3018 10.1764 18.1783 8.06631C17.9991 7.72821 18.0565 7.31466 18.3211 7.03999C18.5808 6.76627 18.9901 6.69091 19.3411 6.85149C19.5722 6.96089 25.0328 9.61709 28.5101 15.1658C31.7319 20.7848 26.6783 24.5672 26.6366 24.5943C26.5515 24.664 24.5027 26.2976 21.853 26.1903C21.7544 26.1857 21.662 26.2238 21.5964 26.2965C21.1573 26.7751 20.6556 27.2092 20.1037 27.5862C19.9325 27.7047 19.7256 27.7454 19.5244 27.7011L19.4842 27.69C19.3006 27.6393 19.1445 27.5212 19.0436 27.3523L18.9002 27.115C18.7001 26.7778 18.7965 26.3387 19.1203 26.1123C19.1739 26.0749 19.2273 26.0374 19.2793 25.9972C19.367 25.9351 19.4124 25.8362 19.4033 25.7292C19.3949 25.62 19.334 25.5283 19.2368 25.4765C18.1996 24.9286 17.2474 24.0657 16.4103 22.9094C14.0784 19.6952 15.8369 16.1111 16.4991 14.7672C16.6006 14.5497 16.7643 14.286 16.9509 13.9807ZM21.6993 15.2587C21.5987 14.8831 21.8075 14.4888 22.1788 14.3618L22.4377 14.2719C22.5813 14.2207 22.7365 14.218 22.8822 14.2583C22.9329 14.2723 22.9845 14.2912 23.0315 14.3178C23.214 14.4138 23.3491 14.5827 23.4033 14.7817C23.8175 16.3254 24.6269 20.2962 23.1825 23.7805C23.135 23.8946 23.1554 24.0184 23.2393 24.1098C23.3189 24.2 23.4398 24.2311 23.556 24.1973C24.71 23.8527 25.5173 23.2257 25.5553 23.1953C25.7149 23.078 29.4107 20.2954 26.9908 16.0749C25.1379 13.1184 22.6152 11.0307 20.8233 9.80162C20.7297 9.73713 20.6121 9.72732 20.5092 9.77842C20.4619 9.80165 20.3491 9.87268 20.3388 10.0336C20.2227 12.0489 19.1192 13.8392 18.4595 14.9111L18.4231 14.9693C18.2765 15.2037 18.1614 15.3902 18.0946 15.5329C17.5085 16.7253 16.1367 19.5184 17.8452 21.872C18.7651 23.1397 19.8017 23.9441 20.9284 24.2669C23.0375 21.0572 22.0523 16.5629 21.6993 15.2587Z" fill="#FFECC3" />
    <path d="M8.69816 33.1244C9.67046 32.6852 10.1027 31.5411 9.66356 30.5688C9.22444 29.5965 8.08026 29.1643 7.10796 29.6034C6.13567 30.0425 5.70344 31.1867 6.14257 32.159C6.58169 33.1313 7.72587 33.5635 8.69816 33.1244Z" fill="#333333" />
    <path d="M9.70922 31.7148C10.5047 31.3555 10.8584 30.4194 10.4991 29.6239C10.1398 28.8284 9.20366 28.4747 8.40814 28.834C7.61263 29.1933 7.25899 30.1294 7.61827 30.925C7.97756 31.7205 8.9137 32.0741 9.70922 31.7148Z" fill="#73A77B" />
    <path d="M38.108 27.8586C37.7162 26.8534 36.5836 26.356 35.5784 26.7478C34.5731 27.1396 34.0757 28.2721 34.4675 29.2774C34.8593 30.2827 35.9918 30.78 36.9971 30.3882C38.0024 29.9965 38.4997 28.8639 38.108 27.8586Z" fill="#333333" />
    <path d="M36.7382 26.7626C36.4176 25.9401 35.491 25.5332 34.6685 25.8538C33.846 26.1743 33.4391 27.1009 33.7597 27.9234C34.0802 28.7459 35.0068 29.1529 35.8293 28.8323C36.6518 28.5118 37.0587 27.5851 36.7382 26.7626Z" fill="#FFB000" />
    <path d="M32.669 1.33492C31.8448 1.96455 31.687 3.14309 32.3167 3.96728C32.9463 4.79147 34.1248 4.9492 34.949 4.31958C35.7732 3.68996 35.9309 2.51141 35.3013 1.68722C34.6717 0.863032 33.4931 0.705304 32.669 1.33492Z" fill="#333333" />
    <path d="M32.0214 2.89203C31.3471 3.40717 31.218 4.37144 31.7332 5.04578C32.2483 5.72011 33.2126 5.84916 33.8869 5.33402C34.5613 4.81887 34.6903 3.85461 34.1752 3.18027C33.66 2.50594 32.6958 2.37689 32.0214 2.89203Z" fill="#F76E9C" />
  </svg>

);
